import React from 'react';

const PaymentLink: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.0818 2C6.53209 2 2 6.50926 2 12H0C0 5.39074 5.44152 0 12.0818 0C16.5691 0 20.5073 2.4478 22.5937 6.05021L22.4882 6.80029L21.1097 7.50536C19.4464 4.25334 16.0157 2 12.0818 2Z" />
      <path d="M11.9181 22C17.4678 22 21.9999 17.4907 21.9999 12H23.9999C23.9999 18.6093 18.5584 24 11.9181 24C7.61131 24 3.81042 21.7452 1.66699 18.379L1.99988 16.95L2.89019 16.4946C4.55349 19.7467 7.98422 22 11.9181 22Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1107 2.12455L22.3972 8.14438L16.8606 7.29987L17.1622 5.32274L20.6545 5.85543L21.1246 1.88916L23.1107 2.12455Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.60262 15.8555L7.13926 16.7L6.83768 18.6771L3.34534 18.1444L2.87526 22.1107L0.88916 21.8753L1.60262 15.8555Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0476 4.5L13.0476 19L11.0476 19L11.0476 4.5L13.0476 4.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4301 6.30268C12.898 6.20248 14.5807 6.55688 15.4885 7.03334L16.3739 7.49807L15.4445 9.26898L14.559 8.80424C14.0278 8.52545 12.7245 8.21898 11.5663 8.29804C10.9938 8.33712 10.5821 8.46358 10.3413 8.6258C10.1565 8.75028 10.0518 8.8992 10.0518 9.20227C10.0518 9.59387 10.1982 9.82667 10.5249 10.0555C10.9186 10.3313 11.4876 10.532 12.2332 10.7694C12.2856 10.7861 12.3392 10.803 12.3938 10.8203C13.0361 11.0234 13.8162 11.2702 14.4327 11.6497C15.1674 12.102 15.8078 12.8294 15.8078 13.9675C15.8078 14.9069 15.476 15.6902 14.853 16.2528C14.2614 16.787 13.497 17.0418 12.7551 17.148C11.2813 17.3591 9.52878 17.0375 8.14987 16.5616L7.20459 16.2354L7.8571 14.3448L8.80238 14.6711C10.0139 15.0892 11.4272 15.3178 12.4716 15.1682C12.9888 15.0941 13.3216 14.9409 13.5125 14.7685C13.6719 14.6245 13.8078 14.4027 13.8078 13.9675C13.8078 13.731 13.7287 13.5649 13.3842 13.3528C12.9865 13.108 12.4321 12.9316 11.6979 12.6979C11.6742 12.6903 11.6504 12.6828 11.6264 12.6751C10.933 12.4543 10.063 12.1739 9.37746 11.6936C8.62493 11.1665 8.05182 10.3684 8.05182 9.20227C8.05182 8.20274 8.51372 7.44552 9.22384 6.96711C9.87796 6.52642 10.6894 6.35324 11.4301 6.30268Z"
      />
    </svg>
  );
};

export default PaymentLink;
